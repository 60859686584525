export const general = {
  logo: "ecorebuild_images/ecorebuildlogov3.png",
  name: "Eco-Rebuild",
  address: "Amirat Alshuraiq St, Amman 00962, Jordan",
  intro: "punchline, paragraph",
  instagram: "https://www.instagram.com/eco_rebuild/",
  // facebook: "https://www.facebook.com/happy.mahdi/",
  // linkedin: "https://www.linkedin.com/in/ben-ayed-mehdi/",
  youtube: "https://www.youtube.com/channel/UCcJRMal-mHtTVnuU33jv9-Q",
  email: "info@ecorebuild.org",
  phone: "+962 780 106 176",
  // website: "mehdibenayed.netlify.app",
  punchline: "Our mission is to create an eco-friendly community.",
  callToAction:
    "Stay updated on our latest projects and initiatives by following us on social media.",
  images: {
    img1: "ecorebuild_images/ecorebuildlogo.PNG",
    img2: "ecorebuild_images/sunsetportrait.jpg",
    img3: "ecorebuild_images/bigteamphoto.jpg",
    img4: "mehdi_images/junctionxteam.jpg",
    img5: "ecorebuild_images/ecorebuildlogo.PNG",
    img6: "ecorebuild_images/sunsetmeetingsquare.jpg",
    img7: "ecorebuild_images/peoplefire.jpeg",
    img8: "mehdi_images/graduationsuccess.jpg",
    img9: "ecorebuild_images/projects/orangelight.jpg",
  },
};
