import LandingPageHeader from "components/LandingPageHeader";
import Component29 from "newComponents/Component29";
import React, { Component } from "react";
const Home = () => {
  return (
    <>
    <LandingPageHeader/>
          
      <Component29 />
    </>
  );
};
export default Home;
